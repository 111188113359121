<style lang="scss">
.a-upload--picture-card {
  width: 510px;
  height: 148px;
}

.l_title {
  display: flex;
  padding: 10px 15px 10px 15px;
  line-height: 30px;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;

  h4 {
    font-size: 14px;
    padding-left: 10px;
    color: #666;
    font-weight: 600;
  }

  span {
    color: #374FC7;
    cursor: pointer;
  }
}
.backClass {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>

<template>
  <div>
    <div class="snx-wrap" slot="content">
      <div class="backClass"><a-button type="primary" @click="FALLBACK"> 返回 </a-button></div>
      <!-- <div class="l_title">
        <h4 v-if="whenFrom === 'add'">添加</h4>
        <h4 v-if="whenFrom === 'edit'">编辑</h4>
        <span @click="FALLBACK">返回</span>
      </div> -->
      <div class="snx-main">
        <a-form-model :model="ruleForm" :rules="rules" ref="ruleForm" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="模板类型" prop="templateType" required>
            <a-radio-group v-model="ruleForm.templateType" :disabled="disabled" @change="checkTemplate">
              <a-radio :value="0">单张胶囊广告</a-radio>
            </a-radio-group>
          </a-form-model-item>
<!--          <a-form-model-item label="透出端口" prop="penetrate" required>-->
<!--            <a-radio-group v-model="ruleForm.penetrate" @change="checkPenetrate" style="lineHeight: 36px">-->
<!--              <a-radio :value="1">APP</a-radio>-->
<!--            </a-radio-group>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="可见项目" prop="project">
            <!-- <a-select v-model="ruleForm.project"  :getPopupContainer="(triggerNode) => triggerNode.parentNode" @visible-change="selectedProject">
              <a-select-option :value="item.id" v-for="item in projectList" :key="item.id">
                {{ item.project_name }}
              </a-select-option>
            </a-select> -->
            <!-- <el-cascader v-model="ruleForm.project" @change="selectedProject" style="width: 350px"
              :options="projectList"
              :props="{emitPath:false, checkStrictly: true, label: 'project_name', children: 'child', value: 'id' }" size="small"
              clearable></el-cascader> -->
            <a-cascader expandTrigger="hover" style="width: 100%;" v-model="ruleForm.project"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode" :options="projectList" :fieldNames="{label: 'project_name', children: 'child', value: 'id'}"
              placeholder="请选择可见项目" @change ="selectedProject"/>

          </a-form-model-item>
          <a-form-model-item label="位置" prop="penetrate" required>
            <a-radio-group v-model="ruleForm.place_to_module" @change="checkPenetrate">
              <a-radio :value="0">首页</a-radio>
              <a-radio :value="1">食住行</a-radio>
              <a-radio :value="2">无忧创业</a-radio>
              <a-radio :value="3">我的权益</a-radio>
              <a-radio :value="4">积分商城</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="绑定模块" prop="module">
            <a-select v-model="ruleForm.module" :getPopupContainer="(triggerNode) => triggerNode.parentNode">
              <a-select-option :value="item.id" v-for="item in moduleList" :key="item.id">
                {{ item.showModuleName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="广告标题" prop="content">
            <a-input v-model.trim="ruleForm.content" style="width: 350px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="模块标题" prop="module_title" v-if="ruleForm.templateType === 1">
            <a-input v-model.trim="ruleForm.module_title" style="width: 350px" :maxlength="5"></a-input>
          </a-form-model-item>
          <a-form-model-item label="广告图片" v-if="ruleForm.templateType === 1">
            <div style="border: 1px solid #bfcbd9;background: #fff;padding: 10px 30px;">
              <div v-for="(item, index) in ruleForm.picArray" :key="index">
                <div style="display: flex;padding-top: 10px;">
                  <div v-if="item.sequence <= 3">
                    <span style="color:#ff4949;margin-right:4px;" v-if="item.sequence == 1">*</span>轮播{{ index + 1 }}:
                  </div>
                  <div v-if="item.sequence == 4">
                    <span style="color:#ff4949;margin-right:4px;">*</span>下图1:
                  </div>
                  <div v-if="item.sequence == 5">
                    <span style="color:#ff4949;margin-right:4px;">*</span>下图2:
                  </div>
                  <div style="padding-left: 10px;">
                    <a-upload list-type="picture-card" :action="IMG_API + '/oss/files/'" :show-file-list="false"
                      :on-success="(file, fileList) => {
                        return fileChange(file, fileList, index);
                      }
                        " :before-upload="file => {
                          return uploadChange(file, index);
                        }
                          " accept="*">
                      <!-- <i class="a-icon-plus"></i> -->
                      <img style="width:146px;height:146px;border-radius: 6px;" v-if="item.image" :src="item.image"
                        class="avatar" />
                      <i class="a-icon-plus" v-else></i>
                    </a-upload>
                    <span>支持格式：gif/jpg/png；大小不超过500KB，尺寸为300*200</span>
                  </div>
                </div>
                <div style="display: flex;padding-top: 10px;align-items:center;">
                  <div style="width:58px;">URL:</div>
                  <a-input v-model.trim="item.advertisement_url" style="width: 350px;padding-left: 18px;"></a-input>
                </div>
                <div style="display: flex;padding-top: 10px;align-items:center;" v-if="item.sequence >= 4">
                  <div style="width:58px;">
                    <span style="color:#ff4949;margin-right:4px;">*</span>主标题:
                  </div>
                  <a-input v-model.trim="item.main_title" style="width: 350px;padding-left: 18px;"
                    :maxlength="5"></a-input>
                </div>
                <div style="display: flex;padding-top: 10px;align-items:center;" v-if="item.sequence >= 4">
                  <div style="width:58px;">
                    <span style="color:#ff4949;margin-right:4px;">*</span>副标题:
                  </div>
                  <a-input v-model.trim="item.subtitle" style="width: 350px;padding-left: 18px;"
                    :maxlength="8"></a-input>
                </div>
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="广告图片" prop="pic" id="pic" v-if="ruleForm.templateType === 0">
            <a-upload list-type="picture-card" :action="IMG_API + '/oss/files/'" :show-file-list="false"
              :before-upload="beforeUpload" :file-list="imagefileList" @change="handleSpPicChange"
              @preview="specialPicturePreview" accept="*">
              <a-icon type="plus" v-if="!ruleForm.pic"></a-icon>
            </a-upload>
            <div style="margin-top: 10px">
              <div>注：</div>
              <div>
                上传尺寸比例建议为<span style="color: red">{{ advertisingWidth }}px*{{ advertisingHeight }}px</span>
              </div>
              <div>
                支持小于2M的jpg、png、jpeg格式的图片{{
                  '&'
                }}支持小于10M的gif格式的动图
              </div>
            </div>
          </a-form-model-item>
          <a-modal :zIndex="100000" :visible="pictureVisible" :footer="null" @cancel="pictureVisible = false">
            <img :src="ruleForm.pic" alt="avatar" class="imgUpload" style="width: 400px;margin-top: 20px;" />
          </a-modal>
          <a-form-model-item label="优先级" prop="priority">
            <a-input v-model.number="ruleForm.priority" placeholder="请输入" style="width: 350px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="广告时间" prop="date">
            <a-range-picker v-model="ruleForm.date" :disabled-date="disabledDate" format="YYYY-MM-DD" />
          </a-form-model-item>
          <a-form-model-item label="URL" v-if="ruleForm.templateType === 0">
            <a-input v-model.trim="ruleForm.advertisement_url" style="width: 350px"></a-input>
          </a-form-model-item>
          <a-form-model-item v-if="whenFrom === 'edit'" label="发布人">
            <div>{{ who }}</div>
          </a-form-model-item>
          <a-form-model-item label="返回按钮" v-if="ruleForm.penetrate === 1">
            <a-radio-group v-model="ruleForm.show_back_btn">
              <a-radio :value="0">不显示</a-radio>
              <a-radio :value="1">显示</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="页面标题" v-if="ruleForm.penetrate === 1">
            <a-input v-model.trim="ruleForm.page_title" style="width: 350px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="是否为特殊活动">
            <a-radio-group v-model="ruleForm.type" @change="">
              <a-radio :value="1">普通广告</a-radio>
              <a-radio :value="2">活动广告位</a-radio>
              <!--              <a-radio :value="2">年终活动</a-radio>-->
              <!--              <a-radio :value="3">开工红包</a-radio>-->
              <a-radio :value="4">热力图（Android）</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="关联活动ID" v-if="ruleForm.type !== 1 && ruleForm.type != null && ruleForm.type !== 4"
            prop="activity_id">
            <a-input v-model.trim="ruleForm.activity_id" style="width: 350px"></a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="submitForm('ruleForm')">提交</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
/**
 * TODO fileOverview
 * --------------
 * @author xiaof
 * @since 2021/3/4.
 */
import { mapActions } from 'vuex';
import { IMG_API } from "@/config";
import {
  insertAdvertising,
  findOneForAdvertising,
  updateAdvertising,
  getAdvertisingProjectList,
  getModuleListByProjectidAndLocation,
  getProjectList, getAllProjectByUserAuth,
  getShunyiAllProject
} from '../../../../api/advertisingNew/advertisingNew';
import moment from 'moment';

export default {
  name: 'newEdit',
  data() {
    const validatePicIsNull = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('请上传一张图片'));
      } else {
        callback();
      }
    };
    const validateProjectIsNull = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('请选择显示的项目'));
      } else {
        callback();
      }
    };
    const validateTimeIsNull = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('请选择时间'));
      } else {
        callback();
      }
    };
    const validateModuleIsNull = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('请选择要绑定的模块'));
      } else {
        callback();
      }
    };
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      whenFrom: '',
      who: '',
      id: '',
      IMG_API: IMG_API,
      ruleForm: {
        pic: '',
        date: [],
        URL: '',
        project: [],
        module_title: '',
        content: '',
        showPic: [],
        templateType: 0,
        picArray: [
          {
            url: '',
            image: '',
            sequence: '1',
            main_title: '',
            subtitle: ''
          },
          {
            url: '',
            image: '',
            sequence: '2',
            main_title: '',
            subtitle: ''
          },
          {
            url: '',
            image: '',
            sequence: '3',
            main_title: '',
            subtitle: ''
          },
          {
            url: '',
            image: '',
            sequence: '4',
            main_title: '',
            subtitle: ''
          },
          {
            url: '',
            image: '',
            sequence: '5',
            main_title: '',
            subtitle: ''
          }
        ],
        // 透出端口
        penetrate: 1,
        // 显示位置
        place_to_module: 0,
        // 优先级
        priority: 1,
        // 选中模块
        module: '',
        // 广告链接地址
        advertisement_url: '',
        // 返回标题
        show_back_btn: 0,
        // 页面标题
        page_title: "",
        // 关联活动id
        activity_id: '',
        // 是否是特殊活动（2、年终回馈，3、开工红包）
        type: 1
      },
      imagefileList: [],
      checkAll: false,
      isIndeterminate: true,
      disabled: true,
      projectList: [],
      rules: {
        pic: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        date: [
          {
            required: true,
            validator: validateTimeIsNull,
            message: '请选择时间',
            trigger: 'blur'
          }
        ],
        project: [
          {
            required: true,
            validator: validateProjectIsNull,
            message: '请选择可见园区',
            trigger: 'change'
          }
        ],
        content: [
          { required: true, message: '请输入广告内容', trigger: 'blur' }
        ],
        module_title: [
          { required: true, message: '请输入模块标题', trigger: 'blur' }
        ],
        // priority: [
        //   { required: true, message: '请输入优先级', trigger: 'blur' }
        // ],
        module: [
          {
            required: true,
            validator: validateModuleIsNull,
            message: '请选择要绑定的模块',
            trigger: 'blur'
          }
        ],
        activity_id: [
          { required: true, message: '请填写关联活动ID', trigger: 'blur' }
        ]
      },
      // 模块列表
      moduleList: [],
      // 模块对象
      moduleObj: {},

      // 广告位宽度 默认:690, 我的权益: 640
      advertisingWidth: 690,
      // banner高度 默认: 200, 我的权益: 186
      advertisingHeight: 200,
      // 是否清空数据
      isEmpty: false,
      // 记录选择显示位置
      recordShowLocation: 0,
      pictureVisible: false
    };
  },
  created() {
    this.init();
    getShunyiAllProject({ check_type: false }).then(res => {
      this.projectList = res.data
      console.log(res, '================>');
    })
  },
  methods: {
    disabledDate(current) {
      console.log(current);
      const currentDate = new Date();
      const threeMonthsLater = new Date(currentDate.setMonth(currentDate.getMonth() + 3));
      return current && current < moment().subtract(1, 'days').endOf('day') || current > threeMonthsLater
    },

    async specialPicturePreview(file) {
      this.pictureVisible = true;
    },
    handleSpPicChange(info) {
      console.log(info);
      this.imagefileList = info.fileList
      if (info.file.status === "removed") {
        this.ruleForm.pic = '';
        this.imagefileList = []
        return;
      }
      if (info.file.status === "done") {
        this.ruleForm.pic = info.file.response.redirect_uri;
        this.imagefileList = [{
          uid: info.file.uid,
          name: info.file.name,
          status: 'done',
          url: info.file.response.redirect_uri
        }]
      }
    },
    FALLBACK() {
      this.$router.go(-1)
    },
    handleCheckAllChange(event) {
      this.ruleForm.project = event.target.checked
        ? this.projectList.map(item => item.id)
        : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.projectList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.projectList.length;
    },
    checkTemplate(e) {
      if (e === '1') {
        this.ruleForm.penetrate = 1;
      }
      if (this.whenFrom == 'edit') {
        return false;
      }
      this.ruleForm.date = [];
      this.ruleForm.project = [];
      this.ruleForm.content = '';
      if (this.ruleForm.templateType == 0) {
        this.ruleForm.pic = [];
        this.ruleForm.URL = '';
        this.ruleForm.showPic = [];
      } else {
        this.ruleForm.picArray = [
          {
            url: '',
            image: '',
            sequence: '1'
          },
          {
            url: '',
            image: '',
            sequence: '2'
          },
          {
            url: '',
            image: '',
            sequence: '3'
          },
          {
            url: '',
            image: '',
            sequence: '4'
          },
          {
            url: '',
            image: '',
            sequence: '5'
          }
        ];
      }
      this.$refs['ruleForm'].validate(valid => { });
    },

    // 选中显示位置
    checkPenetrate(e) {
      if (this.whenFrom === 'edit') {
        // if (this.isEmpty) {

        // } else {
        //   this.isEmpty = true;
        // }
        // this.ruleForm.module = '';
        this.moduleList = [];
        this.getModuleList();
        // 根据显示位置拍断是否清空已上传的图片
        if (this.recordShowLocation !== 3) {
          if (e === 3) {
            this.ruleForm.pic = '';
          }
        } else {
          if (e !== 3) {
            this.ruleForm.pic = '';
          }
        }
      } else {
        this.ruleForm.module = '';
        this.moduleList = [];
        this.getModuleList();
        // 根据显示位置拍断是否清空已上传的图片
        if (this.recordShowLocation !== 3) {
          if (e === 3) {
            this.ruleForm.pic = '';
          }
        } else {
          if (e !== 3) {
            this.ruleForm.pic = '';
          }
        }
      }
      if (e === 3) {
        this.advertisingWidth = 640;
        this.advertisingHeight = 186;
      } else {
        this.advertisingWidth = 690;
        this.advertisingHeight = 200;
      }
      this.recordShowLocation = e;
    },

    // 选中项目
    selectedProject(visible) {
      console.log('===================>',visible);
      if (this.ruleForm.project) {
        // if (!visible) {
        this.ruleForm.module = '';
        this.moduleList = [];
        this.getModuleList();
        // }
      }
    },

    init() {
      if (this.$route.query.type === 'add') {
        this.whenFrom = 'add';
        this.disabled = false;
      } else if (this.$route.query.type === 'edit') {
        this.whenFrom = 'edit';
        this.id = this.$route.query.id;
        this.disabled = true;
        this.moduleObj = JSON.parse(sessionStorage.getItem('MODULE_OBJ'));
      }
      // getProjectList().then(res => {
      //   this.projectList = res.data.items;
      // });
      // getAllProjectByUserAuth().then(res => {
      //   this.projectList = res.data
      // })

      if (this.whenFrom === 'edit') {
        findOneForAdvertising(this.id,this.moduleObj[this.$route.query.moduleId].projectId,)
          .then(res => {
            const { data } = res;
            const date = [
              data.advertisement_start_time,
              data.advertisement_end_time
            ];
            const place_to_module = this.moduleObj[data.module_id].pagePosition;
            this.recordShowLocation = place_to_module;
            this.ruleForm = {
              advertisement_url:
                data.template_type === 0 ? data.advertisement_url : '',
              pic: data.template_type === 0 ? data.advertisement_image_url : '',
              picArray:
                data.template_type === 0 ? [] : data.advertisement_types,
              module_title: data.template_type === 0 ? '' : data.module_title,
              date,
              content: data.advertisement_content,
              templateType: data.template_type,
              penetrate: data.penetrate,
              module: data.module_id,
              priority: data.priority,
              project: this.moduleObj[data.module_id].projectId,
              place_to_module,
              advertisingWidth: place_to_module === 3 ? 640 : 690,
              advertisingHeight: place_to_module === 3 ? 186 : 200,
              show_back_btn: data.show_back_btn,
              page_title: data.page_title,
              type: data.type,
              activity_id: data.activity_id,
              project : data.parent_project_id_list
            };
            this.imagefileList = [{
              uid: '1',
              name: data.advertisement_image_url,
              status: 'done',
              url: data.template_type === 0 ? data.advertisement_image_url : '',
            }]
            this.who = data.update_user;
            this.getModuleList();
          })
          .catch(erroe => {
            console.log(erroe);
          });
      }
    },
    fileChange(res, file, index) {
      // if (!this.ruleForm.picArray[index].image) {
      this.ruleForm.picArray[index].image = res.redirect_uri;
      this.$forceUpdate();
      // } else {
      //   this.$message.error("最多可上传1张图片。")
      // }
    },
    // moveChange(file, index) {
    //   this.ruleForm.picArray[index].image = "";
    // },
    uploadChange(file, index) {
      // if (!this.ruleForm.picArray[index].image) {
      const IS_JPG = file.type === `image/jpg`;
      const IS_JPEG = file.type === `image/jpeg`;
      const IS_PNG = file.type === `image/png`;
      const IS_GIF = file.type === `image/gif`;
      const IS_LT = file.size / 1024 < 500;
      if (!IS_LT) {
        this.$message.error('上传头像图片大小不能超过 500KB!');
      }
      if (IS_JPG || IS_JPEG || IS_PNG || IS_GIF) {
        return file;
      } else {
        this.$message.error(
          '只允许上传 jpg、png、jpeg、gif 格式的图片！并且大小不超过500KB！'
        );
        return false;
      }
      // } else {
      //   this.$message.error("最多上传1张照片")
      //   return false
      // }
    },

    handleAvatarSuccess(res, file) {
      // if (!this.ruleForm.pic) {
      this.ruleForm.pic = res.redirect_uri;
      // this.$refs['ruleForm'].validate((valid) => {

      // });
      this.$refs['ruleForm'].validateField('pic');
      // this.$forceUpdate();
      // } else {
      //   this.$message.error("最多可上传1张图片。")
      // }
    },
    // handleRemove(file) {
    // 因为这里只有一张图片所以直接清空就行了
    // this.ruleForm.pic = "";
    // if (file.response) {
    //   this.ruleForm.pic = this.ruleForm.pic.filter(item => {
    //     if (item !== file.response.redirect_uri) {
    //       return item
    //     }
    //   })
    // } else {
    //   this.ruleForm.pic = this.ruleForm.pic.filter(item => {
    //     if (item !== file.redirect_uri) {
    //       return item
    //     }
    //   })
    // }
    // },
    beforeUpload(file) {
      // if (!this.ruleForm.pic) {
      const index = file.name.lastIndexOf('.');
      const type = file.name.substring(index + 1, file.name.length);
      const isJPG =
        type === 'png' || type === 'jpg' || type === 'jpeg' || type === 'gif';
      if (!isJPG) {
        this.$message.error('上传图片只能是jpg、png、jpeg、gif格式!');
        return false;
      }
      let imgSize = true;
      if (type === 'gif') {
        imgSize = file.size / 1024 / 1024 < 10;
        if (!imgSize) {
          this.$message.error('git图不能超过10M');
          return false;
        }
      } else {
        if (!imgSize) {
          this.$message.error('jpg、png、jpeg图片不能超过2M');
          return false;
        }
      }
      // 图片高度
      let imgHeight = 0;
      // 图片宽度
      let imgWidth = 0;
      const isSize = new Promise((resolve, reject) => {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = () => {
          imgWidth = img.width;
          imgHeight = img.height;
          // 宽度比例是否符合规则
          const ifWidthScale = imgWidth % this.advertisingWidth === 0;
          // 高度比例是否符合规则
          const ifHeightScale = imgHeight % this.advertisingHeight === 0;
          // 宽度倍数
          let widthMultiple = 0;
          // 高度倍数
          let heightMultiple = 0;
          if (ifWidthScale && ifHeightScale) {
            widthMultiple = imgWidth / this.advertisingWidth;
            heightMultiple = imgHeight / this.advertisingHeight;
            if (widthMultiple === heightMultiple) {
              resolve(true);
            } else {
              reject(false);
            }
          } else {
            reject(false);
          }
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error(
            `请上传${this.advertisingWidth}px * ${this.advertisingHeight}px尺寸的图片`
          );
          return Promise.reject();
        }
      );
      return isJPG && imgSize && isSize;
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let data;
          if (this.ruleForm.templateType === 0) {
            data = {
              advertisement_image_url: this.ruleForm.pic,
              advertisement_start_time: moment(this.ruleForm.date[0]).format(
                'YYYY-MM-DD'
              ),
              advertisement_end_time: moment(this.ruleForm.date[1]).format(
                'YYYY-MM-DD'
              ),
              // advertisement_url: this.ruleForm.URL,
              // project_list: this.ruleForm.project,
              advertisement_content: this.ruleForm.content,
              template_type: this.ruleForm.templateType,
              place_to_module: this.ruleForm.place_to_module,
              priority: this.ruleForm.priority,
              penetrate: this.ruleForm.penetrate,
              display: 0,
              module_id: this.ruleForm.module,
              qidaisong: 0,
              advertisement_url: this.ruleForm.advertisement_url,
              show_back_btn: this.ruleForm.show_back_btn,
              page_title: this.ruleForm.page_title,
              type: this.ruleForm.type,
              activity_id: this.ruleForm.type === 1 ? '' : this.ruleForm.activity_id
            };
          } else {
            if (!this.ruleForm.picArray[0].image) {
              this.$message.error('轮播1最少上传一张图片');
              return false;
            }
            if (!this.ruleForm.picArray[3].image) {
              this.$message.error('下图1最少上传一张图片');
              return false;
            }
            if (!this.ruleForm.picArray[3].main_title) {
              this.$message.error('下图1请填写主标题');
              return false;
            }
            if (!this.ruleForm.picArray[3].subtitle) {
              this.$message.error('下图1请填写副标题');
              return false;
            }
            if (this.ruleForm.picArray[3].subtitle.length < 4) {
              this.$message.error('副标题最少输入4个字');
              return false;
            }
            if (!this.ruleForm.picArray[4].image) {
              this.$message.error('下图2最少上传一张图片');
              return false;
            }
            if (!this.ruleForm.picArray[4].main_title) {
              this.$message.error('下图2请填写主标题');
              return false;
            }
            if (!this.ruleForm.picArray[4].subtitle) {
              this.$message.error('下图2请填写副标题');
              return false;
            }
            if (this.ruleForm.picArray[4].subtitle.length < 4) {
              this.$message.error('副标题最少输入4个字');
              return false;
            }
            if (this.ruleForm.type !== 1 && this.ruleForm.activity_id) {
              this.$message.error("请填写活动ID")
              return false
            }
            var picData = [];

            this.ruleForm.picArray.map(item => {
              if (item.image) {
                picData.push(item);
              }
            });
            data = {
              advertisement_start_time: moment(this.ruleForm.date[0]).format(
                'YYYY-MM-DD'
              ),
              advertisement_end_time: moment(this.ruleForm.date[1]).format(
                'YYYY-MM-DD'
              ),
              // advertisement_types: picData,
              project_list: this.ruleForm.project,
              advertisement_content: this.ruleForm.content,
              template_type: this.ruleForm.templateType,
              module_title: this.ruleForm.module_title,
              place_to_module: this.ruleForm.place_to_module,
              priority: this.ruleForm.priority,
              penetrate: this.ruleForm.penetrate,
              display: 0,
              module_id: this.ruleForm.module,
              qidaisong: 0,
              advertisement_url: this.ruleForm.advertisement_url,
              show_back_btn: this.ruleForm.show_back_btn,
              page_title: this.ruleForm.page_title,
              type: this.ruleForm.type,
              activity_id: this.ruleForm.type === 1 ? '' : this.ruleForm.activity_id
            };
          }
          if (this.whenFrom === 'edit') {
            data['id'] = this.id;
            let res = await updateAdvertising(data);
            if (res.code === '200') {
              this.$message.success('操作成功');
              this.$router.back();
            } else {
              this.$message.error(res.msg);
            }
          } else {
            let res = await insertAdvertising(data);
            if (res.code === '200') {
              this.$message.success('操作成功');
              this.$router.back();
            } else {
              this.$message.error(res.msg);
            }
          }
        } else {
          this.$message.error('输入信息有误');
          return false;
        }
      });
    },

    /**网络请求 */
    // 查询模块列表
    async getModuleList() {
      try {
        const result = await getModuleListByProjectidAndLocation({
          projectId: this.ruleForm.project[this.ruleForm.project.length-1],
          type: 4,
          pagePosition: this.ruleForm.place_to_module
        });
        if (result.code === '200') {
          this.moduleList = result.data.map(item => {
            item['showModuleName'] = `${this.backShowLocation()}${item.moduleName
              }`;
            return item;
          });
        }
      } catch (error) { }
    },

    /**数据处理 */
    // 返回显示位置
    backShowLocation() {
      switch (this.ruleForm.place_to_module) {
        case 0:
          return '首页';
        case 1:
          return '食住行';
        case 2:
          return '无忧创业';
        case 3:
          return '我的权益';
        default:
          return '';
      }
    },
    changeProject(){
      console.log(this.ruleForm.project);
    }
  }
};
</script>
